import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Loader } from '../components/Loader';

const UploadProgressModal = lazy(() => import('./UploadProgressModal'));
const DashboardConfigModal = lazy(() => import('./DashboardConfig'));
const DashboardWidgetSettingsModal = lazy(() =>
  import('./DashboardWidgetSettings')
);
const EditChartLabelsModal = lazy(() => import('./EditChartLabels'));

const MODAL_COMPONENTS = {
  DATASET_UPLOAD_PROGRESS: UploadProgressModal,
  DASHBOARD_CONFIG: DashboardConfigModal,
  WIDGET_SETTINGS: DashboardWidgetSettingsModal,
  EDIT_CHART_LABELS: EditChartLabelsModal
};

const RootModal = ({ modalName, modalProps, modalShow }) => {
  if (!modalName) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalName];
  return (
    <Suspense
      fallback={
        <div
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            height: '100vh',
            width: '100%'
          }}
        >
          <Loader />
        </div>
      }
    >
      <SpecificModal {...modalProps} modalShow={modalShow} />
    </Suspense>
  );
};

export default connect(state => state.utils)(RootModal);
