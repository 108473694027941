import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import intl from './intl';
import utils from './utils';
import user from './user';
import datasets from './datasets';
import dashboard from './dashboard';

export default history =>
  combineReducers({
    router: connectRouter(history),
    intl,
    utils,
    datasets,
    user,
    dashboard
  });
