import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'antd';
export const Loader = props => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh'
      }}
    >
      <div className="insyt-loader" />
    </div>
  );
};

export const SeedingLoader = props => {
  return (
    <div className="SeedingLoader">
      <div>
        <Icon
          type="sync"
          spin
          style={{ fontSize: '2rem', marginBottom: '1rem' }}
        />

        <p>
          <FormattedMessage
            defaultMessage="Please be patient... Seeding data"
            id="App.SeedingLoader.Patience"
          />
        </p>
        {/* <p>This will happen only once... for this dataset <FormattedMessage id="App.SeedingLoader.Patience" /> </p> */}
      </div>
    </div>
  );
};
