import { replace } from 'connected-react-router';
import { setStorage, getStorage } from '../utils/helpers';

export const changeLang = (lang, url) => {
  let userPreferences = JSON.parse(getStorage('userPreferences'));
  let tester = RegExp('^(/en/|/fr/)', 'g').test(url);

  if (tester) {
    userPreferences
      ? setStorage(
          'userPreferences',
          JSON.stringify({
            ...JSON.parse(getStorage('userPreferences')),
            lang
          })
        )
      : setStorage('userPreferences', JSON.stringify({ lang }));

    return dispatch => {
      dispatch(replace(url.replace(/\/en\/|\/fr\//g, `/${lang}/`)));
      dispatch({ type: 'CHANGE_LANGUAGE', lang: lang || 'en' });
    };
  } else {
    userPreferences
      ? setStorage(
          'userPreferences',
          JSON.stringify({
            ...JSON.parse(getStorage('userPreferences')),
            lang: 'en'
          })
        )
      : setStorage('userPreferences', JSON.stringify({ lang: 'en' }));
    return dispatch => {
      dispatch(replace(`/en${url}`));
      dispatch({ type: 'CHANGE_LANG', lang: 'en' });
    };
  }
};
