import { CHANGE_LANGUAGE } from '../utils/types';
import { getCookie } from '../utils/helpers';

export default (state = { lang: getCookie('site_lang') || 'en' }, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        lang: action.lang || 'en'
      };

    default:
      return state;
  }
};
