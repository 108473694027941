export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const REARRANGE_LAYOUT = 'REARRANGE_LAYOUT';
export const EDIT_DASHBOARD_WIDGET = 'EDIT_DASHBOARD_WIDGET';

export const LOG_OUT = 'LOG_OUT';

export const GET_DATASETS = 'GET_DATASETS';
export const GET_DATASETS_SUCCESS = 'GET_DATASETS_SUCCESS';
export const GET_DATASETS_FAILED = 'GET_DATASETS_FAILED';

export const GET_DATASET = 'GET_DATASET';
export const GET_DATASET_SUCCESS = 'GET_DATASET_SUCCESS';
export const GET_DATASET_FAILED = 'GET_DATASET_FAILED';

export const GET_RUNNING_AVERAGE = 'GET_RUNNING_AVERAGE';
export const GET_RUNNING_AVERAGE_SUCCESS = 'GET_RUNNING_AVERAGE_SUCCESS';
export const GET_RUNNING_AVERAGE_FAILED = 'GET_RUNNING_AVERAGE_FAILED';

export const DELETE_DATASET = 'DELETE_DATASET';
export const DELETE_DATASET_SUCCESS = 'DELETE_DATASET_SUCCESS';
export const DELETE_DATASET_FAILED = 'DELETE_DATASET_FAILED';

export const SET_DATASET = 'SET_DATASET';

export const GET_DATASET_RECORDS = 'GET_DATASET_RECORDS';
export const GET_DATASET_RECORDS_SUCCESS = 'GET_DATASET_RECORDS_SUCCESS';
export const GET_DATASET_RECORDS_FAILED = 'GET_DATASET_RECORDS_FAILED';

export const SEED_ELASTIC_SEARCH = 'SEED_ELASTIC_SEARCH';
export const SEED_ELASTIC_SEARCH_SUCCESS = 'SEED_ELASTIC_SEARCH_SUCCESS';
export const SEED_ELASTIC_SEARCH_FAILED = 'SEED_ELASTIC_SEARCH_FAILED';

export const GET_COLUMN_UNIQUE_VALUES = 'GET_COLUMN_UNIQUE_VALUES';
export const GET_COLUMN_UNIQUE_VALUES_SUCCESS = 'GET_COLUMN_UNIQUE_VALUES_SUCCESS';
export const GET_COLUMN_UNIQUE_VALUES_FAILED = 'GET_COLUMN_UNIQUE_VALUES_FAILED';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED';

export const SAVE_DASHBOARD = 'SAVE_DASHBOARD';
export const SAVE_DASHBOARD_NAME = 'SAVE_DASHBOARD_NAME';
export const SAVE_DASHBOARD_SUCCESS = 'SAVE_DASHBOARD_SUCCESS';
export const SAVE_DASHBOARD_FAILED = 'SAVE_DASHBOARD_FAILED';

export const CREATE_DASHBOARD_WIDGET = 'CREATE_DASHBOARD_WIDGET';
export const CREATE_DASHBOARD_WIDGET_SUCCESS = 'CREATE_DASHBOARD_WIDGET_SUCCESS';
export const CREATE_DASHBOARD_WIDGET_FAILED = 'CREATE_DASHBOARD_WIDGET_FAILED';

export const REMOVE_DASHBOARD_WIDGET = 'REMOVE_DASHBOARD_WIDGET';

export const SET_ACTIVE_DASHBOARD = 'SET_ACTIVE_DASHBOARD';
export const SET_ALL_DASHBOARDS = 'SET_ALL_DASHBOARDS';