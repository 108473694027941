import React, { Suspense, lazy, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";

import { Switch, Route, Redirect } from 'react-router-dom';
import { Loader } from '../components/Loader';
import { IntlProvider } from 'react-intl';
import { ConnectedRouter } from 'connected-react-router';

import localeData from '../intl/data.json';

import { changeLang } from '../actions/intl';
import RootModal from '../modals/RootModal';
import { getCookie } from '../utils/helpers';
import { setUser } from '../actions/user';

const Main = lazy(() => import('./Main'));

const App = props => {
  const { siteLanguage, history, setUser, user } = props;

  useEffect(() => {
    let lang = window.location.pathname.split('/')[1] || getCookie('site_lang');
    if (siteLanguage !== lang) {
      window.location = ((window.location.pathname + window.location.search).replace(`/${lang}`, `/${siteLanguage}`))
    }
  }, [siteLanguage]);

  useEffect(() => {
    const user = getCookie('user') !== '' ? JSON.parse(getCookie('user')) : undefined;
    const token = getCookie('user_token') !== '' ? getCookie('user_token') : undefined;
    const disallowed = user ? (process.env.REACT_APP_ACCESS_ANALYTICS || '').split(',').findIndex(role => role === user.role) === -1 : true;

    const mappingCall = () => {
      axios({
        method:'GET',
        headers:{
          'x-access-token':token,
           Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        url:`${process.env.REACT_APP_API}/sync-field-mappings`,
      }).catch(err => console.log('it does not break the code..'))
    }

    if (user && disallowed) {
      return window.location.assign(process.env.REACT_APP_INSYT_APP_URL ? `${process.env.REACT_APP_INSYT_APP_URL}/en/forms` : 'http://localhost:3000/en/forms');
    }
    if (user && token) {
       setUser(user, token);
      return mappingCall()
    }

    const lang = window.location.pathname.split('/')[1] || 'en';
    return window.location.assign(`${process.env.REACT_APP_SSO_WEB_URL}/${lang}/signout?continue=${encodeURI(window.location.href)}`);
    // return window.location.assign(
    //   process.env.REACT_APP_INSYT_APP_URL
    //     ? `${process.env.REACT_APP_INSYT_APP_URL}/en/login?appReferrerId=InsytAnalytics`
    //     : 'http://localhost:3000/en/login?appReferrerId=InsytAnalytics'
    // );
  }, []); //eslint-disable-line

  if (!user) {
    return <Loader />;
  }

  if (user) {
    return (
      <IntlProvider locale={siteLanguage} messages={localeData[siteLanguage]}>
        <ConnectedRouter history={history}>
          <Suspense fallback={<Loader />}>
            <RootModal />
            <Switch>
              <Route
                path="/:lang/"
                render={rest => <Main {...rest} siteLanguage={siteLanguage} />}
              />
              <Redirect from="/" to={`/${siteLanguage || 'en'}/`} />
              {/* <Redirect to={`/${siteLanguage || 'en'}/datasets`} /> */}
            </Switch>
          </Suspense>{' '}
        </ConnectedRouter>
      </IntlProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    siteLanguage: state.intl.lang,
    user: state.user.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeLang: (lang, url) => {
      dispatch(changeLang(lang, url));
    },
    setUser: (user, token) => {
      dispatch(setUser(user, token));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
