import {
  LOG_OUT,
  GET_DATASETS,
  GET_DATASETS_FAILED,
  GET_DATASETS_SUCCESS,
  GET_DATASET,
  GET_DATASET_FAILED,
  GET_DATASET_SUCCESS,
  DELETE_DATASET,
  DELETE_DATASET_FAILED,
  DELETE_DATASET_SUCCESS,
  SET_DATASET,
  GET_DATASET_RECORDS,
  GET_DATASET_RECORDS_FAILED,
  GET_DATASET_RECORDS_SUCCESS,
  GET_COLUMN_UNIQUE_VALUES,
  GET_COLUMN_UNIQUE_VALUES_FAILED,
  GET_COLUMN_UNIQUE_VALUES_SUCCESS,
  SEED_ELASTIC_SEARCH,
  SEED_ELASTIC_SEARCH_SUCCESS,
  SEED_ELASTIC_SEARCH_FAILED
} from '../utils/types';

const initialState = {
  filterList: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RECORDS_FILTER':
      let tester = {};
      if (!state.filterList) {
        tester[`${action.data.heading}`] = [action.data.value];
      } else if (state.filterList && action.data.add) {
        tester = state.filterList;
        tester[`${action.data.heading}`] = state.filterList[
          `${action.data.heading}`
        ]
          ? [...state.filterList[`${action.data.heading}`], action.data.value]
          : [action.data.value];
      } else {
        tester = state.filterList;
        if ((tester[`${action.data.heading}`] || []).length === 1) {
          delete tester[`${action.data.heading}`];
        } else {
          tester[`${action.data.heading}`] = (
            state.filterList[`${action.data.heading}`] || []
          ).filter(fil => fil !== action.data.value);
        }
      }
      return {
        ...state,
        filterList: tester
      };
    case 'CANCEL_UNSAVED_RECORDS_FILTER':
      return {
        ...state,
        filterList: action.filterList
      };
    case 'REMOVE_RECORDS_FILTER':
      let newFilList = { ...state.filterList };

      action.column && delete newFilList[action.column];
      return {
        ...state,
        filterList: action.column ? newFilList : {}
      };
    case 'SET_ACTIVE_SORT':
      return {
        ...state,
        activeSort: {
          _sort: action.data[0].columnName || '',
          _order: action.data[0].direction || ''
        }
      };
    case GET_DATASETS:
      return {
        ...state,
        fetching: true
        // tableRecords: undefined
      };

    case GET_DATASETS_SUCCESS:
      return {
        ...state,
        fetching: false,
        datasets: action.data
      };

    case GET_DATASETS_FAILED:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    case GET_DATASET:
      return {
        ...state,
        tableRecords: undefined,
        fetchingDataset: true
      };

    case GET_DATASET_SUCCESS:
      return {
        ...state,
        fetchingDataset: false,
        dataset: action.data
      };

    case GET_DATASET_FAILED:
      return {
        ...state,
        fetchingDataset: false,
        dataset: undefined,
        error: action.error
      };
    case DELETE_DATASET:
      return {
        ...state,
        deletingDataset: true
      };
    case DELETE_DATASET_FAILED:
      return {
        ...state,
        deletingDataset: false,
        error: action.error
      };
    case DELETE_DATASET_SUCCESS:
      return {
        ...state,
        deletingDataset: false,
        datasets: {
          total: state.datasets.total - 1,
          count: state.datasets.count - 1,
          items: state.datasets.items.filter(item => item.id !== action.id)
        }
      };
    case SET_DATASET:
      return {
        ...state,
        dataset: action.dataset,
        tableRecords: undefined
      };
    case 'SET_WIDGET_TO_VISUAL':
      return {
        ...state,
        widgetfromDash: action.widget
      };

    case GET_DATASET_RECORDS:
      return {
        ...state,
        fetchingRecords: true
      };

    case GET_DATASET_RECORDS_SUCCESS:
      return {
        ...state,
        fetchingRecords: false,
        dataset: {
          ...state.dataset,
          recordCount: action.unfiltered // solves issue of total records less than filtered records on table
            ? action.data.total
            : state.dataset.recordCount
        },
        activeFilterList: action.activeFilterList,
        tableRecords:
          !state.tableRecords ||
          state.tableRecords.items.length === 5 ||
          action.reset // resets the tableRecords when navigating to larger table
            ? action.data
            : {
                ...state.tableRecords,
                items: [...state.tableRecords.items, ...action.data.items]
              }
      };

    case GET_DATASET_RECORDS_FAILED:
      return {
        ...state,
        fetchingRecords: false,
        error: action.error
      };
    case GET_COLUMN_UNIQUE_VALUES:
      return {
        ...state,
        fetchingUniqueVals: true,
        uniqueVals: undefined
      };

    case GET_COLUMN_UNIQUE_VALUES_SUCCESS:
      return {
        ...state,
        fetchingUniqueVals: false,
        uniqueVals: action.data
      };

    case GET_COLUMN_UNIQUE_VALUES_FAILED:
      return {
        ...state,
        fetchingUniqueVals: false,
        error: action.error
      };

    case SEED_ELASTIC_SEARCH:
      return {
        ...state,
        dataset: undefined,
        seeding: true
      };
    case SEED_ELASTIC_SEARCH_SUCCESS:
      return {
        ...state,
        seeding: false
      };
    case SEED_ELASTIC_SEARCH_FAILED:
      return {
        ...state,
        seeding: false,
        error: action.error
      };
    case LOG_OUT:
      return initialState;
    default:
      return state;
  }
};
