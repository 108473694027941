import uuidv4 from 'uuid/v4';

import {
  LOG_OUT,
  GET_DASHBOARD,
  GET_DASHBOARD_FAILED,
  GET_DASHBOARD_SUCCESS,
  CREATE_DASHBOARD_WIDGET,
  CREATE_DASHBOARD_WIDGET_FAILED,
  CREATE_DASHBOARD_WIDGET_SUCCESS,
  SAVE_DASHBOARD,
  SAVE_DASHBOARD_FAILED,
  SAVE_DASHBOARD_SUCCESS,
  SAVE_DASHBOARD_NAME,
  REMOVE_DASHBOARD_WIDGET,
  REARRANGE_LAYOUT,
  EDIT_DASHBOARD_WIDGET,
  GET_RUNNING_AVERAGE,
  GET_RUNNING_AVERAGE_SUCCESS,
  GET_RUNNING_AVERAGE_FAILED,
  SET_ACTIVE_DASHBOARD,
  SET_ALL_DASHBOARDS
} from '../utils/types';

const initialState = {
  allDashboards: [],
};

export const dummyDashboard = {
  name: 'Untitled dashboard',
  id: `${uuidv4()}`,
  // accountId: '',
  widgets: [
    {
      id: 1,
      dashWidget: 'number',
      dashWidgetSubType: 'value',
      layoutConfig: {
        w: 4,
        h: 4,
        x: 0,
        y: 0,
        i: 1,
        moved: false,
        static: false
      }
    },
    {
      id: 2,

      dashWidget: 'number',
      dashWidgetSubType: 'value',
      layoutConfig: {
        w: 4,
        h: 4,
        x: 4,
        y: 0,
        i: 2,
        moved: false,
        static: false
      }
    },
    {
      id: 3,

      dashWidget: 'number',
      dashWidgetSubType: 'value',
      layoutConfig: {
        w: 4,
        h: 4,
        x: 8,
        y: 0,
        i: 2,
        moved: false,
        static: false
      }
    },
    {
      id: 4,

      dashWidget: 'table',
      dashWidgetSubType: 'pivot',
      layoutConfig: {
        w: 12,
        h: 14,
        x: 0,
        y: 4,
        i: 2,
        moved: false,
        static: false
      }
    },
    {
      id: 5,

      dashWidget: 'piechart',
      dashWidgetSubType: 'pie',
      layoutConfig: {
        w: 6,
        h: 12,
        x: 0,
        y: 18,
        i: 2,
        moved: false,
        static: false
      }
    },
    {
      id: 6,

      dashWidget: 'barchart',
      dashWidgetSubType: 'simple-bar',
      layoutConfig: {
        w: 6,
        h: 12,
        x: 6,
        y: 18,
        i: 2,
        moved: false,
        static: false
      }
    }
  ]
};

const setWidgets = (dash = {}) => {
  var widgets = [];
  if (dash.widgets) {
    dash.widgets.forEach(wd => {
      if (!widgets.map(wwd => { return wwd.id; }).includes(wd.id)) {
        widgets = widgets.concat(wd);
      }
    });
  }
  return { ...dash, widgets };
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_DASHBOARD:
      return {
        ...state,
        fetchingDashboard: true
      };

    case GET_DASHBOARD_SUCCESS:
      // console.log(setWidgets(action.data.items[0]));
      return {
        ...state,
        fetchingDashboard: false,
        allDashboards: action.data.items.filter(dash => dash.id),
        activeDashboard: action.data.items.length > 0 ? setWidgets(action.data.items[0]) : dummyDashboard,
      };

    case SET_ACTIVE_DASHBOARD:
      return {
        ...state,
        savingDashboard: false,
        fetchingDashboard: false,
        activeDashboard: action.data
      };

    case SET_ALL_DASHBOARDS:
      return {
        ...state,
        allDashboards: action.data
      };

    case GET_DASHBOARD_FAILED:
      return {
        ...state,
        fetchingDashboard: false,
        error: action.error
      };
    case GET_RUNNING_AVERAGE:
      return {
        ...state
      };
    case GET_RUNNING_AVERAGE_SUCCESS:
      return {
        ...state,
        runningAverageData: action.data
      };

    case GET_RUNNING_AVERAGE_FAILED:
      return {
        ...state,
        error: action.error
      };

    case SAVE_DASHBOARD:
      return {
        ...state,
        savingDashboard: true,
        error: action.error
      };

    case SAVE_DASHBOARD_NAME:
      return {
        ...state,
        activeDashboard: { ...state.activeDashboard, name: action.name }
      };

    case SAVE_DASHBOARD_SUCCESS:
      return {
        ...state,
        savingDashboard: false
        // error: action.error
      };

    case SAVE_DASHBOARD_FAILED:
      return {
        ...state,
        savingDashboard: false,
        error: action.error
      };

    case CREATE_DASHBOARD_WIDGET:
      return {
        ...state,
        creating: true
      };

    case CREATE_DASHBOARD_WIDGET_SUCCESS:
      let activeDashboard = action.data.oldId
        ? {
          ...state.activeDashboard,
          widgets: state.activeDashboard.widgets.map(acDash =>
            acDash.id === action.data.oldId
              ? {
                ...action.data,
                title: acDash.title ? acDash.title : 'untitled',
                layoutConfig: acDash.layoutConfig,
                ui: acDash.ui
              }
              : acDash
          )
        }
        : {
          ...state.activeDashboard,
          widgets: [...state.activeDashboard.widgets, action.data]
        };

      return {
        ...state,
        creating: false,
        activeDashboard
      };

    case CREATE_DASHBOARD_WIDGET_FAILED:
      return {
        ...state,
        creating: false,
        error: action.error
      };

    case REMOVE_DASHBOARD_WIDGET:
      let activeDashboardremoved = {
        ...state.activeDashboard,
        widgets: state.activeDashboard.widgets.filter(
          acDash => acDash.id !== action.widgetId
        )
      };

      return {
        ...state,
        activeDashboard: activeDashboardremoved
      };

    case REARRANGE_LAYOUT:
      let activeDashboardlayout = {
        ...state.activeDashboard,
        widgets: state.activeDashboard.widgets.map((active, index) => ({
          ...active,
          layoutConfig: action.data[index]
        }))
      };

      return {
        ...state,
        activeDashboard: activeDashboardlayout
      };

    case EDIT_DASHBOARD_WIDGET:
      // Provide key to update specific parts of dash widget. Always add id in widget payload
      switch (action.key) {
        case 'layoutConfig':
          let activeDashboardlayConfig = {
            ...state.activeDashboard,
            widgets: state.activeDashboard.widgets.map(active =>
              active.id === action.widget.i
                ? { ...active, layoutConfig: action.widget }
                : active
            )
          };

          return {
            ...state,
            activeDashboard: activeDashboardlayConfig
          };
        default:
          let activeDashboardDefault = {
            ...state.activeDashboard,
            widgets: state.activeDashboard.widgets.map(active =>
              active.id === action.widget.id ? action.widget : active
            )
          };

          return {
            ...state,
            activeDashboard: activeDashboardDefault
          };
      }
    case LOG_OUT:
      return initialState;
    default:
      return state;
  }
};
