const STORAGE_PRFX = process.env.REACT_APP_STORAGE_PREFIX || 'esk_';

// ::: cookie
export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = `${STORAGE_PRFX + cname}=${cvalue};${expires};path=/;domain=${process.env.REACT_APP_COOKIES_DOMAIN}`;
};

export const getCookie = cname => {
  var name = STORAGE_PRFX + cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      if (c.split('=')[1].length !== 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
};
export const deleteCookie = cname => {
  var expires = 'expires=-1';
  document.cookie = `${STORAGE_PRFX + cname}=;${expires};path=/;domain=${process.env.REACT_APP_COOKIES_DOMAIN}`;
};

// ::: storage
export const setStorage = (name, value) => {
  return localStorage.setItem(STORAGE_PRFX + name, value);
}
export const getStorage = (name, value) => {
  return localStorage.getItem(STORAGE_PRFX + name, value);
}
export const delStorage = (name) => {
  return localStorage.removeItem(STORAGE_PRFX + name);
}

// check permission
export const hasP = (permission, myRole) => {
  const thisRole = JSON.parse(getCookie('user') || '{}').role;
  if (thisRole) {
    var check = (process.env[`REACT_APP_${permission}`] || '').split(',').findIndex(role => role === thisRole);
    if (check === -1) {
      return false;
    }
    return true;
  }
  return false;
}

export const rerouteUser = user => {
  const canAccessInsyt = (process.env.REACT_APP_ACCESS_INSYT || '').split(',').findIndex(role => role === user.role) !== -1;

  const canAccessAbis = (process.env.REACT_APP_ACCESS_ABIS || '').split(',').findIndex(role => role === user.role) !== -1;

  if (canAccessInsyt) {
    return window.location.assign(
      process.env.REACT_APP_INSYT_APP_URL
        ? `${process.env.REACT_APP_INSYT_APP_URL}/en/forms`
        : 'http://localhost:3000/en/forms'
    );
  }

  if (canAccessAbis) {
    return window.location.assign(
      process.env.REACT_APP_ABIS_APP_URL
        ? `${process.env.REACT_APP_ABIS_APP_URL}/en/`
        : 'http://localhost:3002/en/'
    );
  }
};


export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}